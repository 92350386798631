import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../layout/layout';
import RepertoireList from '../../components/RepertoireList/RepertoireList';
import { Container, Section } from 'bloomer';
import config from '../../../data/SiteConfig';

export default class RepertoirePage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Repertoire | ${config.siteTitle}</title>  
        </Helmet>
        
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <h1 class="title is-1">Repertoire</h1>
            <hr />
            <RepertoireList />
          </Container>
        </Section>
      </Layout>
    )
  }
}