import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Content } from "bloomer";
import _ from "lodash";

export default class RepertoireList extends React.Component {
  repertoire(data) {
    let repertoireList = [];
    // Iterate through each post, putting all found repertoireList into `repertoireList`
    _.each(data, (edge) => {
      _.each(edge.edges, (node) => {
        if (_.get(node, "node.frontmatter.repertoire")) {
          repertoireList = repertoireList.concat(
            node.node.frontmatter.repertoire
          );
        }
      });
    });
    // Eliminate duplicate repertoireList
    repertoireList = _.uniq(repertoireList);
    repertoireList = _.orderBy(repertoireList, ["title"], ["asc"]);
    // Make tag pages
    return repertoireList.map((work) => {
      return (
        <li>
          <h3 class="title is-6">{work.title}</h3>
          <p class="subtitle is-6">{work.composer}</p>
        </li>
      );
    });
  }
  render() {
    const myQuery = graphql`
      query {
        projects: allMarkdownRemark(
          filter: { fields: { template: { eq: "projects" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                repertoire {
                  title
                  composer
                }
              }
            }
          }
        }
      }
    `;
    return (
      <StaticQuery
        query={myQuery}
        render={(data) => (
          <Content>
            <ul>{this.repertoire(data)}</ul>
          </Content>
        )}
      />
    );
  }
}
